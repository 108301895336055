<template>
    <div class="change-password">
        <div class="page-header">
            <StackRouterHeaderBar />
        </div>
        <div class="password items-center key_current">
            현재 비밀번호
            <div class="value">
                <input type="password" class="password-input" v-model="password" @input="onInputPassword" />
            </div>
            <div class="info" v-if="showPasswordInfo">비밀번호는 필수 항목입니다.</div>
        </div>
        <div class="password items-center">
            <div class="key">
                <span>변경할 비밀번호</span>
            </div>
            <div class="value">
                <input type="password" class="password-input" v-model="passwordNew" @input="onInputPasswordNew" />
            </div>
            <div class="info" v-if="showPasswordNewInfo" v-html="$translate('PLACEHOLDER_PASSWORD')" />
        </div>
        <div class="password items-center">
            <div class="key">
                <span>변경할 비밀번호 확인</span>
            </div>
            <div class="value">
                <input
                    type="password"
                    class="password-input"
                    v-model="passwordConfirm"
                    @input="onInputPasswordConfirm"
                />
            </div>
            <div class="info" v-if="showPasswordConfirmInfo">입력한 비밀번호가 일치하지 않습니다.</div>
        </div>
        <div class="change-password-btn">
            <button class="item btn btn-primary" @click="change_password">변경하기</button>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ChangePasswordPage',
    data: () => ({
        password: '',
        passwordNew: '',
        passwordConfirm: '',
        showPasswordInfo: false,
        showPasswordNewInfo: false,
        showPasswordConfirmInfo: false,
    }),
    methods: {
        checkPassword() {
            if (this.password.length === 0) {
                this.showPasswordInfo = true
                return false
            }

            if (this.passwordNew.length < 6) {
                this.showPasswordNewInfo = true
                return false
            }

            if (this.passwordNew !== this.passwordConfirm) {
                this.showPasswordConfirmInfo = true
                return false
            }
            return true
        },
        onInputPassword(event) {
            this.showPasswordInfo = this.password.length === 0
            this.password = event.target.value
        },
        onInputPasswordNew(event) {
            this.showPasswordInfo = this.password.length === 0
            this.showPasswordNewInfo = this.passwordNew.length < 6
            this.passwordNew = event.target.value
        },
        onInputPasswordConfirm(event) {
            this.showPasswordInfo = this.password.length === 0
            this.showPasswordNewInfo = this.passwordNew.length < 6
            this.showPasswordConfirmInfo = false
            this.passwordConfirm = event.target.value
        },
        async change_password() {
            if (this.checkPassword()) {
                try {
                    const ret = await userService.changePassword({
                        current_password: this.password,
                        password: this.passwordNew,
                    })
                    this.$toast.success(ret.msg)
                    this.$stackRouter.pop()
                } catch (e) {
                    this.$toast.error(e.data)
                }
            }
        },
    },
}
</script>
